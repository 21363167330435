<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <q-support-submenu></q-support-submenu>
        <v-row class="mx-5 q-sticky-buffer">
            <v-col cols=12>
                <v-card>
                    <q-support-tickets-data-table :rows="25" title="Support Tickets">
                    </q-support-tickets-data-table>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
//table data + search
import QSupportTicketsDataTable from '@/components/datatables/QSupportTicketsDataTable.vue';
import QSupportSubmenu from '@/components/navigation/Submenus/QSupportSubmenu.vue';


export default {
    data() {
        return {
            search: '',
        }
    },
    components: {
        QSupportTicketsDataTable,
        QSupportSubmenu
    }
}

</script>
