<template>
    <div>
        <h2>Create a Support Ticket</h2>
        <form>
            <v-select label="Department" return-object :items="departments" item-text="category" item-value="category" v-model="current_category"></v-select>
            <v-text-field :counter="10" label="Name" required v-model="name"></v-text-field>
            <v-text-field label="E-mail" disabled required v-model="email"></v-text-field>
            <v-text-field label="Subject" required v-model="subject"></v-text-field>
            <v-row>
                <v-col cols="12">
                    <v-textarea label="How can we help you today?" v-model="comment"></v-textarea>
                    <!--XX-->
                    <div>
                        <span v-for="(attachment, key) in attachments" :key="key">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <span :href="attachment.content_url" target="_blank" v-bind="attrs" v-on="on" :key="key" class="elevation-0 attachment-thumbnail-wrapper pa-3 ma-3">
                                        <v-icon color="primary" style="font-size: 40px !important">fas fa-file-alt fa-3x</v-icon>
                                    </span>
                                </template>
                                <span>{{ attachment.file_name }}</span>
                            </v-tooltip>
                        </span>
                    </div>
                    <v-col style="position: relative; max-width: 300px">
                        <q-file-upload ref="dropzone" text="Attach a file" :upload-url="'/api/my/tickets/0/attachment'" :params="{}" accepted-files="application/vnd.ms-excel, application/csv, application/vnd.ms-word,.csv,.txt,.jpg,.png,.gif,.docx,.doc,.xlsx,.pdf,.msg" upload-name="document" :max-files="null" v-on:complete="uploadComplete" v-on:fileadded="uploading = true"></q-file-upload>
                        <div style="position: absolute; top: 0px; right: 5px">
                            <v-tooltip right>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon color="warning" dark v-bind="attrs" v-on="on"> fas fa-question-circle </v-icon>
                                </template>
                                <span>Click or drag attachments into the drop zone.<br />You must include a comment to SUBMIT</span>
                            </v-tooltip>
                        </div>
                    </v-col>
                    <v-row cols="12" class="my-5 d-flex justify-space-between">
                        <v-btn :disabled="uploading || comment === null" class="py-4" color="primary" @click="createTicket">
                            submit
                            <v-progress-circular indeterminate size="30" v-if="ticket_loading"></v-progress-circular>
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" outlined @click="loadStep(2)" class="py-4">Back</v-btn>
                    </v-row>
                </v-col>
            </v-row>
        </form>
        <v-dialog v-model="dialog" width="500">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                    {{ dialog_text }}
                    <v-progress-circular v-if="ticket_loading" indeterminate color="primary"></v-progress-circular>
                    <div v-if="dialog_text == 'Problems!'">
                        <v-btn @click="dialog = false">Close</v-btn>
                    </div>
                </v-card-title>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import QuilityAPI from "@/store/API/QuilityAPI.js";
import Category from "@/store/Models/Category";
import QFileUpload from "@/components/utils/QFileUpload.vue";

export default {
    props: ['category'],
    data() {
        return {
            dialog: false,
            dialog_text: null,
            current_category: null,
            name: null,
            email: null,
            comment: null,
            subject: null,
            attachments: [],
            uploading: false,
            ticket_loading: false
        };
    },
    mounted: function() {
        this.name = this.$auth.auth_user["http://quility.com/user_data"].name;
        this.email = this.$auth.auth_user["http://quility.com/user_data"].email;
        if (this.category) {
            this.current_category = this.category;
        }
    },
    methods: {
        createTicket: function() {
            var g = this;
            this.dialog = true;
            this.dialog_text = "Submitting Ticket...";
            this.ticket_loading = true;
            var ticket = {
                type: this.current_category,
                tags: ["quility", "dashboard", "Division_" + this.divisions[0]],
                subject: this.subject,
                name: this.name,
                comment: this.comment,
                priority: "normal",
            };
            QuilityAPI.newTicket(ticket, this.attachments).then(function(t) {
                g.ticket_loading = false;
                if (t.error) {
                    g.dialog_text = "Problems! " + t.message;
                } else {
                    g.trackSupportTicket("New Ticket Created");
                    g.dialog_text = "Complete!";
                    QuilityAPI.getTickets();
                    setTimeout(g.$router.push("/support/tickets/" + t.ticket.id), 2000);
                    g.current_category = {};
                    //g.name = null
                    //g.email = null
                    g.comment = null;
                    g.subject = null;
                    g.attachments = [];
                }
            });
        },
        trackSupportTicket: function(what) {
            gtag("event", "Support", {
                event_category: "Ticket",
                event_label: what,
            });
        },
        uploadComplete: function(data) {
            this.uploading = false;
            this.attachments.push(data.data);
            this.$refs.dropzone.removeFile(data.file);
        },
        getCategoryByName: function(n) {
            n = n.replace("_", " ");
            for (var i = 0; i < this.departments.length; i++) {
                if (this.departments[i].category == n) {
                    return this.departments[i];
                }
            }
            return null;
        },
    },
    computed: {
        categories: function() {
            return Category.query()
                .where("zendesk_category_id", (value) => value != 0 && value != null)
                .get();
        },
        departments: function() {
            var cats = [...this.categories];
            cats.push({
                category: "Quility U",
                dropdown_category_name: "Quility U",
            });
            cats.push({
                category: "Quility Tech",
                dropdown_category_name: "Quility Tech",
            });
            cats.push({
                category: "Events",
                dropdown_category_name: "Events",
            });
            cats.push({
                category: "Business Intelligence",
                dropdown_category_name: "Business Intelligence",
            });
            cats.push({
                category: "Quility HQ Help",
                dropdown_category_name: "Quility HQ Help",
            });
            cats.push({
                category: "Bug",
                dropdown_category_name: "Bug",
            });
            cats.push({
                category: "Feature Request",
                dropdown_category_name: "Feature Request",
            });
            return cats.sort(function(a, b) {
                if (a.category.toUpperCase() < b.category.toUpperCase()) {
                    return -1;
                }
                if (a.category.toUpperCase() > b.category.toUpperCase()) {
                    return 1;
                }
                return 0;
            });
        },
    },
    watch: {
        'category': function(newV) {
            this.current_category = newV
        }
    },
    components: {
        QFileUpload,
    },
};
</script>
<style></style>
<style scoped>
.v-tour__target--relative {
    margin-top: 0px !important;
}
</style>