<template>
    <SupportCard class="text-center">
        <div class="mb-4">
            <!-- Div prevents v-avatar become squared at top -->
            <v-avatar color="q_blue_1" style="border-radius:50%;">
                <v-icon dark>icon-q-support</v-icon>
            </v-avatar>
        </div>
        <h5>Didn't find what you were looking for?</h5>
        <v-hover v-slot="{ hover }">
            <v-btn v-if="hasRole(['SuperAdmin', 'Staff', 'Exec'])" class="my-4" dark :color="hover ? '#fff' : '#0083eb'" target="_blank" href="https://form.jotform.com/212626256941154" depressed :style="{'color': hover ? '#0083eb' : 'white' }">
                SUBMIT A SUPPORT TICKET
            </v-btn>
            <v-btn v-else class="my-4" dark :color="hover ? '#fff' : '#0083eb'" :to="'/support/tickets/create?category=' + encodeURIComponent(category)" depressed :style="{'color': hover ? '#0083eb' : 'white' }">
                SUBMIT A SUPPORT TICKET
            </v-btn>
        </v-hover>
    </SupportCard>
</template>
<script>
import SupportCard from './base/Card.vue'

export default {
    name: 'RequestFeatureCard',

    components: {
        SupportCard
    },
    props: ['category']
}
</script>