<template>
    <v-container fluid class="grey lighten-5 pa-0 ma-0">
        <q-support-submenu has-demo="true" v-on:demo="startDemo()"></q-support-submenu>
        <v-row cols="12" class="mx-4 d-flex justify-space-between q-sticky-buffer">
            <v-btn outlined color="primary" :to="{ name: 'Support' }">
                <v-icon class="pr-3">fas fa-chevron-left</v-icon>back
            </v-btn>
        </v-row>
        <common-solutions :category="categoryObj" />
    </v-container>
</template>
<script>
import QSupportSubmenu from '@/components/navigation/Submenus/QSupportSubmenu.vue';
import CommonSolutions from './CommonSolutions.vue'
import Category from '@/store/Models/Category'
import QuilityAPI from '@/store/API/QuilityAPI.js'

export default {
    components: { CommonSolutions, QSupportSubmenu },

    props: {
        category: { type: String }
    },

    computed: {
        categoryObj() {
            const category = Category.query().where('category', (value) => value === this.category).get();

            if (!category || category.length == 0) {
                return this.$router.push('/404')
            }

            return category[0];
        }
    },

    watch: {
        categoryObj: {
            handler(newVal) { QuilityAPI.getSupportCategoryPages(newVal.id) },
            immediate: true
        }
    }
}
</script>