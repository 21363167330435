<template>
    <div class="support-resouces-grid">
        <div
            v-for="(item) in items"
            class="support-resouces-grid__item-wrapper"
            :key="item.text"
        >
            <router-link
                class="support-resouces-grid__item" 
                :to="item.link || '/'"
            >
                <v-icon>{{ item.icon }}</v-icon>
                <p class="text-center mt-4">{{ item.text }}</p>
            </router-link>            
        </div>
    </div>
</template>

<script>
/*
type Item = {
    icon: string,
    text: string,
    link: string,
}
*/
export default {
    name: 'SupportResourcesGrid',
    props: {
        items: {
            type: Array,
        }
    }
}
</script>

<style lang="scss" scoped>
    $gap: 8px;
    $grid-item-bg: #DFDDED;

    .support-resouces-grid {
        display: flex;
        flex-wrap: wrap;
        margin: -$gap;

        &__item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 130px;
            background: $grid-item-bg;
            color: #000000;
            fill: #000000;
            transition: background-color 0.3s ease-in-out;

            &:hover {
                text-decoration: none !important;
                color: #000000 !important;
                background: lighten($color: $grid-item-bg, $amount: 4%);
            }

            p {
                margin: 0;
                font: normal normal normal 18px/24px Montserrat;
            }

            &-wrapper {
                width: 33%;
                padding: $gap;
                box-sizing: border-box;
            }

            .v-icon.v-icon {
                font-size: 1.75rem !important;
            }
        }
    }
</style>