<template>
    <v-container fluid class="grey lighten-5 pa-0 ma-0">
        <q-support-submenu has-demo="true" v-on:demo="startDemo()"></q-support-submenu>
        <v-row cols="12" class="mx-4 d-flex justify-center q-sticky-buffer">
            <v-col cols="12" sm="6">
                <v-card>
                    <v-card-text class="pa-6">
                        <q-create-support-ticket :category="category"></q-create-support-ticket>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import QSupportSubmenu from '@/components/navigation/Submenus/QSupportSubmenu.vue';
import QCreateSupportTicket from '@/components/support/QCreateSupportTicket.vue'
export default {
    props: [],
    data() {
        return {}
    },
    computed: {
        'category': function() {
            return this.$route.query.category
        }
    },
    methods: {

    },
    components: {
        QSupportSubmenu,
        QCreateSupportTicket
    }
}
</script>
<style lang="scss">
.q-multi-line-btn {
    .v-btn__content {
        width: 100%;
    }
}
</style>